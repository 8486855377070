<template>
    <div>
        <div class="d-flex justify-space-between align-center pb-2">
            <p style="font-size: 20px;"
               class="font-weight-semibold text-black">
                {{ $t('Finance') }}
            </p>
        </div>
        <v-divider class="pb-7"></v-divider>
        <v-row>
            <v-col cols="12"
                   md="3"
                   sm="4"
                   v-for="card in cards"
                   :key="card.icon">
                <template>
                    <v-card flat
                            class="rounded-lg border-card">
                        <v-card-text>
                            <v-avatar color="#e8e8e8"
                                      size="26"
                                      class="pa-4 rounded-lg">
                                <img :src="card.imge">
                            </v-avatar>
                        </v-card-text>

                        <v-card-text class="mt-n1">
                            <span class="text-caption text-black"> {{ $t(card.text) }}</span>
                        </v-card-text>

                        <v-card-text class="mt-n2"> 
                            <span v-if="card.format" class="font-weight-bold text-h6 text-black"> {{ convertNumber(card.value) }}</span>
                            <span v-if="!card.format" class="font-weight-bold text-h6 text-black"> {{ card.value }}</span>
                        </v-card-text>
                    </v-card>
                </template>
            </v-col>
        </v-row>


        <v-card id="user-list"
                class="mt-4">
            <v-card-text class="d-flex justify-space-between align-center flex-wrap pb-0">
                <div class="d-flex align-center pb-5">
                    <span class="font-weight-semibold text-base text-green text-color">
                        {{ $t('Finance Listing') }}
                    </span>
                </div>
                <div class="d-flex align-center pb-5">

                    <v-select :items="statusOptions"
                              single-line
                              dense
                              outlined
                              hide-details
                              v-model="searchQuery"
                              :label="$t('Status')"
                              @change="onChangeFilter()"
                              item-value="id"
                              item-text="name"
                              class="user-list-search me-3"></v-select>

                    <!-- <v-menu v-model="menu3"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field single-line
                                          dense
                                          outlined
                                          hide-details
                                          v-bind="attrs"
                                          :placeholder="$t('Select Booking Date')"
                                          @input="onChangeFilter()"
                                          v-on="on"
                                          class="user-list-search me-3"
                                          v-model="selectedDate">
                                <template v-slot:append>
                                    <v-icon @click="on.click">mdi-calendar-month</v-icon>
                                </template>
                            </v-text-field>
                        </template>
                        <v-date-picker v-model="selectedDate"
                                       @input="menu3 = false"></v-date-picker>
                    </v-menu> -->

                    <v-text-field v-model="searchText"
                                  dense
                                  outlined
                                  prepend-inner-icon="mdi-magnify"
                                  hide-details
                                  :placeholder="$t('Search')"
                                  @input="onSearch()"
                                  width="33px"
                                  class="user-search me-3"></v-text-field>
                </div>
            </v-card-text>

            <v-data-table v-model="selectedTableData"
                          :headers="tableColumns"
                          :items="financeListTable"
                          :options.sync="options"
                          :footer-props="{ itemsPerPageOptions: [5, 10] }"
                          header-color="purple darken-2"
                          :server-items-length="totalFinanceListTable"
                          :loading="loading"
                          :class="{ 'light-mode': shouldApplyClass, 'dark-mode': !shouldApplyClass }"
                          class="custom-header-color my-data-table">

                <!-- trending header -->
                <template #[`header.trending`]>
                    <v-icon size="22">
                        {{ icons.mdiTrendingUp }}
                    </v-icon>
                </template>

                <!-- id -->
                <template #[`item.id`]="{ item }">

                    <div class="text-black font-size"> {{ item.id }}</div>

                </template>

                <template #[`item.userName`]="{ item }">
                    <div class="text-black font-size">{{ item.userName }}</div>
                </template>

                <template #[`item.created_at`]="{ item }">
                    <div class="text-black font-size">{{ item.created_at }}</div>
                </template>
                <template #[`item.on_date`]="{ item }">
                    <div class="text-black font-size">{{ item.on_date }}</div>
                </template>
                <template #[`item.status`]="{ item }">
                    <v-chip :color="resolveStatusVariant(item.status).variant"
                            :class="`v-chip-light-bg ${resolveStatusVariant(item.status).variant}--text font-weight-thin text-caption`">
                        {{ $t(resolveStatusVariant(item.status).name) }}
                    </v-chip>
                </template>
                <template #[`item.total_amount`]="{ item }">
                    <div class="text-black font-size">{{ convertNumber(item.total_amount) }}</div>
                </template>
                <template #[`item.boat_commission`]="{ item }">
                    <div class="text-black font-size">{{item.boat_commission.toFixed(2) }}</div>
                </template>
                <template #[`item.booking_vat`]="{ item }">
                    <div class="text-black font-size">{{ item.booking_vat.toFixed(2) }}</div>
                </template>

                <!-- actions -->
                <template #[`item.actions`]="{ item }">
                    <div class="d-flex align-center justify-center">

                        <v-tooltip bottom>
                            <template #activator="{ on, attrs }">
                                <v-btn icon
                                       small
                                       color="#00B2A9"
                                       v-bind="attrs"
                                       @click="printData(item.id)"
                                       v-on="on">
                                    <v-icon size="20"
                                            class="text-green">
                                        {{ icons.mdiPrinter }}
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('Print') }}</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                            <template #activator="{ on, attrs }">
                                <v-btn icon
                                       small
                                       color="#00B2A9"
                                       v-bind="attrs"
                                       @click="viewFinance(item)"
                                       v-on="on">
                                    <v-icon size="20"
                                            class="text-green">
                                        {{ icons.mdiEyeOutline }}
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('View') }}</span>
                        </v-tooltip>
                    </div>
                </template>
            </v-data-table>
        </v-card>


        <div id="print"
             style="display: none;">
            <h1 style="font-family: 'Inter', sans-serif;">{{ $t('Finance Details') }}</h1>
            <h2 style="font-family: 'Inter', sans-serif;">{{ $t('User Details') }}</h2>

            <table style=" border-collapse: collapse;">
                <tr>
                    <span v-for="item in items"
                          :key="item.id">
                        <th style="text-align:left;padding:5px 5px 0;font-family: 'Inter', sans-serif;">
                            {{ $t(item.id) }}
                        </th>
                    </span>
                </tr>
                <tr>
                    <span v-for="item in items"
                          :key="item.id">
                        <td style="text-align:left;padding:5px 5px 0;font-family: 'Inter', sans-serif;">
                            {{ $t(item.text) }}
                        </td>
                    </span>
                </tr>
            </table>

            <h2 style="font-family: 'Inter', sans-serif;">{{ $t('Booking Details') }}</h2>
            <div style="display: flex;justify-content: space-between;">
                <span style="font-family: 'Inter', sans-serif;font-weight: bold;">{{ this.boatName }}</span>
                <span
                      style="font-family: 'Inter', sans-serif;">
                    Status: {{ boatDetail.status == 'in_progress' ? 'In Progress' : boatDetail.status }}
                </span>
            </div>

            <p style="font-family: 'Inter', sans-serif;">
                {{ city ? city.name : '' }} | {{ country ? country.name : '' }}

                <!-- {{ city ? city.name : '' }} - {{ country ? country.name : '' }} -->
            </p>
            <p style="font-family: 'Inter', sans-serif;">
                {{ created_at }}

                <!-- {{ created_at }} - {{ from_time }} -> {{ to_time }} -->
            </p>

            <hr>

            <div style="display: flex;justify-content: space-between;"
                 v-for="item in items1"
                 :key="text">
                <span style="padding-top: 5px;font-weight: bold;font-family: 'Inter', sans-serif;">{{ $t(item.text)
                }}</span>
                <span style="padding-top: 5px;font-weight: bold;font-family: 'Inter', sans-serif;">{{ item.text == 'Price of Booking' ? boatDetail.booking_price +' '+'SAR' +' '+`(${totalHours} Hours)` : item.id }}</span>
            </div>

            <hr>

            <div style="display: flex;justify-content: space-between;">
                <span style="padding-top: 5px;font-weight: bold;font-family: 'Inter', sans-serif;">{{ $t('Order Amount')
                }}</span>
                <span style="padding-top: 5px;font-weight: bold;font-family: 'Inter', sans-serif;">
                    {{ boatDetail.order_amount }} SAR

                    <!-- {{ boatDetail.order_amount.toLocaleString() }} SAR -->
                </span>
            </div>
            <div style="display: flex;justify-content: space-between;">
                <span style="padding-top: 5px;font-weight: bold;font-family: 'Inter', sans-serif;">{{ $t('VAT') }}</span>
                <span style="padding-top: 5px;font-weight: bold;font-family: 'Inter', sans-serif;">
                    {{ boatDetail.booking_vat }}%
                    <!-- {{ boatDetail.booking_vat }}% -->
                </span>
            </div>

            <hr>

            <div style="display: flex;justify-content: space-between;">
                <span style="padding-top: 5px;font-weight: bold;font-family: 'Inter', sans-serif;">{{ $t('Total Amount')
                }}</span>
                <span style="padding-top: 5px;font-weight: bold;font-family: 'Inter', sans-serif;">
                    {{ boatDetail.total_amount }} SAR
                    <!-- {{ boatDetail.total_amount }} -->
                </span>
            </div>

            <hr>

            <div style="display: flex;justify-content: space-between;">
                <span style="padding-top: 5px;font-weight: bold;font-family: 'Inter', sans-serif;">
                {{ $t('MyBoat Commission') }}</span>
                <span style="padding-top: 5px;font-weight: bold;font-family: 'Inter', sans-serif;">
                    {{ myBoatCommision }} SAR ({{ (boatDetail.boat_commission) }}%)
                    <!-- {{ boatDetail.boat_commission.toFixed(2) }} -->
                </span>
            </div>

        </div>


    </div>
</template>

<script>
import router from '@/router';
import financeServices from '@/services/finance-services';
import printJS from 'print-js'
import store from '@/store';
import {
mdiCheck, mdiClose, mdiDeleteOutline,
mdiDotsVertical, mdiDownload, mdiEyeOutline, mdiPlus,
mdiPrinter,
mdiTrendingUp
} from '@mdi/js';
import moment from 'moment';
import useFinanceList from './useFinanceList';
import decimalCurrency from '@/@core/utils/decimalCurrency';

export default {
    data: () => ({
        // searchText: '',
        menu3: false,
        items: [
        ],
        printShow: false,
        boatName: '',
        shouldApplyClass: true,
        created_at: '',
        from_time: '',
        to_time: '',
        boatDetail: {},
        boatPackages: [],
        city: '',
        country: '',
        totalHours: '',
        transactionId: '',
        myBoatCommision: '',
        items1: [
        ],
        statusOptions: [
            // {
            //     "id": 'pending',
            //     "name": 'Pending'
            // },
            {
                id: '',
                name: 'All'
            },
            {
                "id": 'confirmed',
                "name": 'Confirmed'
            },
            {
                "id": 'cancelled',
                "name": 'Cancelled'
            },
            {
                "id": 'completed',
                "name": 'Completed'
            },
            {
                "id": 'in-progress',
                "name": 'InProgress'
            }
        ],
        cards: [

        ],
    }),
    computed: {
        // filteredItems()
        // {
        //     if (this.searchText)
        //     {
        //         const query = this.searchText.toLowerCase();
        //         return this.financeListTable.filter(item =>
        //             Object.values(item).some(
        //                 value => value && value.toString().toLowerCase().includes(query)
        //             )
        //         );
        //     }
        //     return this.financeListTable;
        // }
    },
    created()
    {
        store.subscribe((mutation) =>
        {
            if (mutation.type === 'accounts/SET_DARK_MODE')
            {
                this.shouldApplyClass = mutation.payload;
            }
        })
    },
    mounted()
    {
        let isDark = localStorage.getItem('my-active-theme');
        if (isDark == 'dark')
        {
            this.shouldApplyClass = false;
        }
        financeServices.getcardList().then(resp =>
        {

            if (resp.statusCode == 200)
            {
                // console.log(resp.data.dashboard[0]);
                this.cards = [
                    { imge: require('@/assets/images/svg/card-icon1.svg'), icon2: 'mdi-dots-horizontal', format: false, text: 'Total Number of Bookings', value: resp.data.dashboard[0].all_bookings_count, img: require('@/assets/images/character.png'), percent: '13.02%', month: 'From May', color: '#16C098' },
                    { imge: require('@/assets/images/svg/card-icon2.svg'), icon2: 'mdi-dots-horizontal', format: true, text: 'Total Bookings Value', value: resp.data.dashboard[0].all_bookings_count, img: require('@/assets/images/error.png'), percent: '0.32%', month: 'From May', color: '#FF0000' },
                    { imge: require('@/assets/images/svg/card-icon9.svg'), icon2: 'mdi-dots-horizontal', format: true, text: 'Paid Invoices', value: resp.data.dashboard[0].completed_bookings_count, img: require('@/assets/images/character.png'), percent: '3.02%', month: 'From May', color: '#16C098' },
                    { imge: require('@/assets/images/svg/card-icon4.svg'), icon2: 'mdi-dots-horizontal', format: false, text: 'No. of Requests Pending', value: resp.data.dashboard[0].pending_bookings_count, img: '', percent: '', month: '' },
                ]
            }
        })
    },

    methods: {
        convertNumber(value)
        {
            return decimalCurrency.convertNumber(value);
        },
        printData(id)
        {

            financeServices.viewFinanceList(id).then(resp =>
            {
                if (resp.statusCode == 200)
                {
                    this.boatDetail = resp.data.bookings[0]
                    if (this.boatDetail.boat_commission != null && this.boatDetail.boat_commission > 0)
                    {
                        this.myBoatCommision = (this.boatDetail.total_amount / 100) * this.boatDetail.boat_commission
                        this.myBoatCommision = this.myBoatCommision.toFixed(2)
                    }
                    this.city = resp.data.bookings[0].city
                    this.country = resp.data.bookings[0].city?.country
                    this.boatName = resp.data.bookings[0].boat.boat_name

                    this.created_at = moment(new Date(resp.data.bookings[0].on_date)).format('DD-MM-yyyy')

                    // this.from_time = resp.data.bookings[0].from_time
                    // this.to_time = resp.data.bookings[0].to_time

                    if (resp.data.bookings[0].from_time)
                    {
                        this.created_at = `${this.created_at} | ${resp.data.bookings[0].from_time}`
                    }
                    if (resp.data.bookings[0].to_time)
                    {
                        this.created_at = `${this.created_at} - ${resp.data.bookings[0].to_time}`
                    }

                    /// get hours//////
                    if (resp.data.bookings[0].from_date_time && resp.data.bookings[0].to_date_time)
                    {
                        const fromDate = new Date(resp.data.bookings[0].from_date_time)
                        const toDate = new Date(resp.data.bookings[0].to_date_time)

                        const timeDiff = toDate.getTime() - fromDate.getTime()
                        const hours = Math.floor(timeDiff / (1000 * 60 * 60))

                        this.totalHours = hours
                    }

                    this.transactionId = this.boatDetail?.invoice_id ? this.boatDetail?.invoice_id : `${'' + '|'}${this.boatDetail?.invoice_reference}` ? this.boatDetail?.invoice_reference : ''
                    this.items1 = [
                        { text: 'Transaction ID', id: this.transactionId },
                        { text: 'Booking ID', id: this.boatDetail?.id },
                        { text: 'Payment Method', id: this.boatDetail?.payment_method },
                        { text: 'Price of Booking', id: `${this.boatDetail?.booking_price} ` + 'SAR' + ' ' + `${(this.totalHours)} Hours` },
                    ]
                    if (resp.data.bookings[0].booking_packages)
                    {
                        this.boatPackages = resp.data.bookings[0].booking_packages
                       
                    }
                    if (this.boatPackages)
                    {
                        this.boatPackages.forEach(element =>
                        {
                            this.items1.push({
                                text: element.name,
                                id: `${element.price} ` + 'SAR',
                            })
                        })
                    }
                    if (resp.data.bookings[0]?.user)
                    {
                        this.items = [
                            { id: 'User ID', text: resp.data.bookings[0]?.user.id },
                            { id: 'Username', text: `${resp.data.bookings[0]?.user.first_name} ${resp.data.bookings[0]?.user.last_name}` },
                            { id: 'Mobile No.', text: resp.data.bookings[0]?.user.phone },
                            { id: 'Email Address', text: resp.data.bookings[0]?.user.email },
                            { id: 'Booking Date', text: resp.data.bookings[0].on_date },
                            { id: 'Requested Date', text: moment(new Date(resp.data.bookings[0].created_at)).format('DD-MM-yyyy') },
                        ]
                    }
                    store.set('accounts/loader', true);
                    setTimeout(() =>
                    {
                        store.set('accounts/loader', false)
                        document.getElementById('print').style.display = 'block';
                        const printContent = document.getElementById("print")
                        printJS({ printable: printContent.innerHTML, type: 'raw-html' });
                        document.getElementById('print').style.display = 'none';
                    }, 3000)
                }
            });
        },

        viewFinance(item)
        {
            router.push(`/view-finance/${item.id}`);
        },
    },


    setup()
    {
        const {
            financeListTable,
            tableColumns,
            options,
            statusTable,
            totalFinanceListTable,
            loading,
            searchQuery,
            selectedDate,
            selectedTableData,
            resolveStatusVariant,
            onChangeFilter,
            onSearch,
            searchText,
        } = useFinanceList()

        const actionOptions = [
            // { title: 'Download', icon: mdiDownloadOutline },
            { title: 'Approve', icon: mdiDeleteOutline },
            { title: 'Reject', icon: mdiDeleteOutline },
        ]

        return {
            tableColumns,
            statusTable,
            options,
            totalFinanceListTable,
            financeListTable,
            loading,
            searchQuery,
            selectedDate,
            selectedTableData,
            actionOptions,
            resolveStatusVariant,
            onChangeFilter,
            onSearch,
            searchText,

            icons: {
                mdiTrendingUp,
                mdiPlus,
                mdiDownload,
                mdiDeleteOutline,
                mdiDotsVertical,
                mdiEyeOutline, mdiCheck, mdiClose, mdiPrinter
            },

        }
    },

}
</script>

<style lang="scss" scoped>
.text-black {
    color: #000
}

.font-size {
    font-size: 14px;
    font-weight: 400;
}

.text {
    color: #16C098;
}

#user-list {
    .user-list-actions {
        max-width: 7.81rem;
    }

    .user-list-search {
        max-width: 10.625rem;
        border-radius: 20px;
    }

    .user-list-status {
        max-width: 11.3rem;
    }
}


::v-deep .v-input--selection-controls {
    margin-top: 0px;
    padding-top: 4px !important;
}

.user-search {
    max-width: 17.625rem;
    border-radius: 20px;
}

.font-size {
    font-size: 14px;
    font-weight: 400;
}

.text-color {
    color: #00B2A9;
}

.my-data-table ::v-deep .v-data-table-header th {
    font-weight: bold;
    font-size: 14px;
    height: 40px;
}


// ::v-deep .v-data-table>.v-data-table__wrapper>table>tbody>tr>td,
// .v-data-table>.v-data-table__wrapper>table>thead>tr>td,
// .v-data-table>.v-data-table__wrapper>table>tfoot>tr>td {
//     font-size: 0.875rem;
//     height: 3.125rem;
//     border-left: 1px solid #efeded !important;
// }

::v-deep .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:not(.v-data-table__mobile-row),
.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>th:not(.v-data-table__mobile-row) {
    border-bottom: none !important;
}

::v-deep .theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr:last-child>th {
    color: #131C24 !important;
    background: rgba(165, 174, 182, 0.15) !important;

}

::v-deep .theme--dark.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:not(.v-data-table__mobile-row),
.theme--dark.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>th:not(.v-data-table__mobile-row) {
    border-bottom: none !important;
}

// ::v-deep .v-data-table>.v-data-table__wrapper>table>thead>tr:last-child>th {
//     border-bottom: thin solid rgba(94, 86, 105, 0.14);
//     border-right: 1px solid #efeded;
// }
</style>

import { ref, watch } from '@vue/composition-api';


import financeServices from '@/services/finance-services';
import { useUtils } from '@core/libs/i18n';

import store from '@/store';
import moment from 'moment';

import debounce from "lodash/debounce";

const { t } = useUtils()

export default function useFinanceList()
{
    const financeListTable = ref([])

    // Table Handlers
    const tableColumns = [
        {
            text: 'ID',
            value: 'id',
            sortable: false,
        },

        { text: t('Name'), value: 'userName', sortable: false, align: 'center' },
        { text: t('Requested Date'), value: 'created_at', sortable: false, align: 'center' },
        { text: t('Booking Date'), value: 'on_date', sortable: false, align: 'center' },
        { text: t('Status'), value: 'status', sortable: false, align: 'center' },
        { text: t('Total Amount (SAR)'), value: 'total_amount', sortable: false, align: 'center' },
        { text: t('Boat Commission %'), value: 'boat_commission', sortable: false, align: 'center' },
        { text: t('VAT %'), value: 'booking_vat', sortable: false, align: 'center' },
        {
            text: t('Actions'),
            value: 'actions',
            align: 'center',
            sortable: false,
        },
    ]

    const searchQuery = ref('')
    const selectedDate = ref('')
    const searchText = ref('')

    const options = ref({
        // sortBy: ['id'],
        // sortDesc: [true],
        page: 1,
        itemsPerPage: 10
    })
    const totalFinanceListTable = ref(0)
    const loading = ref(false)
    const selectedTableData = ref([])

    const fetchFinance = () =>
    {

        store.set('accounts/loader', true);

        let date = selectedDate?.value ? selectedDate?.value : null;
        let status = searchQuery?.value ? searchQuery?.value : null;
        let searchTextNew = searchText?.value ? searchText?.value : null;
        financeServices.getList(options, date, status, searchTextNew).then(resp =>
        {
            if (resp.statusCode == 200)
            {
                store.set('accounts/loader', false);
                let data = resp?.data?.bookings;
                data.forEach(element =>
                {
                    element.created_at = moment(new Date(element.created_at)).format('DD-MM-yyyy')
                    element.on_date = moment(new Date(element.on_date)).format('DD-MM-yyyy')
                    if (element?.user)
                    {
                        element.userName = element.user.first_name;
                    }
                });

                totalFinanceListTable.value = resp?.data?.pagination?.count

                financeListTable.value = [];
                financeListTable.value = data;
                loading.value = false;
            }
        })
    };

    const onSearch = debounce(() =>
    {
        options.value.page = 1;
        loading.value = true
        selectedTableData.value = []
        fetchFinance();
    }, 700);

    const onChangeFilter = () =>
    {
        options.value.page = 1;
        loading.value = true
        selectedTableData.value = []
        fetchFinance();
    };

    watch([options], () =>
    {
        // start loading
        loading.value = true
        selectedTableData.value = []
        fetchFinance()
    })

    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*

    const resolveStatusVariant = status =>
    {
        if (status === 'completed') return { variant: 'success', name: 'Completed' }
        if (status === 'hold') return { variant: 'secondary', name: 'Pending' }
        if (status === 'cancelled') return { variant: 'error', name: 'Cancelled' }
        if (status === 'confirmed') return { variant: 'primary', name: 'Confirmed' }
        if (status === 'in_progress') return { variant: 'warning', name: 'In Progress' }
        if (status === '2') return { variant: 'error', name: 'Rejected' }
        // if (status === 'pending_boat_inspection') return { variant: 'info', name: 'Pending Inspection' }

        return { variant: 'secondary', name: 'Not Available' }
    }

    const resolveClientAvatarVariant = status =>
    {
        if (status === 'Partial Payment') return 'primary'
        if (status === 'Paid') return 'error'
        if (status === 'Downloaded') return 'secondary'
        if (status === 'Draft') return 'warning'
        if (status === 'Sent') return 'info'
        if (status === 'Past Due') return 'success'

        return 'primary'
    }

    return {
        tableColumns,
        options,
        financeListTable,
        totalFinanceListTable,
        loading,
        searchQuery,
        selectedDate,
        selectedTableData,
        fetchFinance,
        // resolveBoatStatusVariantAndIcon,
        resolveClientAvatarVariant,
        resolveStatusVariant,
        onChangeFilter,
        onSearch,
        searchText
    }
}
